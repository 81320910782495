export default defineNuxtPlugin({
  name: 'criteoEvent',
  setup() {
    const { CRITEO_ACCOUNT_ID } = useRuntimeConfig().public

    return {
      provide: {
        criteoEvent: (id: string, price: number) => {
          window.criteo_q = window.criteo_q || []
          window.criteo_q.push(
            {
              event: 'setAccount',
              account: CRITEO_ACCOUNT_ID
            },
            { event: 'setSiteType', type: useNuxtApp().$deviceSize },
            {
              event: 'viewBasket',
              item: [
                { id: id, price, quantity: 1 }
              ]
            }
          )
        }
      }
    }
  }
})
