import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin(nuxtApp => {
  let observer: IntersectionObserver;

  nuxtApp.vueApp.directive('inview', {
    mounted(el: HTMLElement, binding) {
      observer = new IntersectionObserver((entries) => {
        const { arg, value } = binding
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            value()

            if (arg === 'once') {
              observer.unobserve(el)
            }
          }
        })
      })
      observer.observe(el)
    },
    unmounted(el, binding, vnode, prevVnode) {
      observer.disconnect()
    },
    getSSRProps() { return {} },
  })
})
